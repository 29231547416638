<template>
  <div class="position-relative">
    <slot />
    <div class="position-absolute" style="top: 0; left: 0; bottom: 0; right: 0">
      <canvas
        ref="canvas"
        class="w-100 h-100"
        :style="{ cursor }"
        @mousedown="onMouseDown"
        @mousemove="onMouseMove"
        @mouseup="onMouseUp"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
const convertToRatio = ({ x, y }, { width, height }) => ({ x: x / width, y: y / height });
const convertFromRatio = ({ x, y }, { width, height }) => ({ x: x * width, y: y * height });

class Box {
  constructor(canvas, topLeft, bottomRight) {
    this._canvas = canvas;
    this._topLeft = topLeft;
    this._bottomRight = bottomRight;
    this._columns = [];
  }

  static createFromPoints(canvas, [point1, point2]) {
    const topLeft = {
      x: Math.min(point1.x, point2.x) / canvas.width,
      y: Math.min(point1.y, point2.y) / canvas.height,
    };
    const bottomRight = {
      x: Math.max(point1.x, point2.x) / canvas.width,
      y: Math.max(point1.y, point2.y) / canvas.height,
    };
    return new this(canvas, topLeft, bottomRight);
  }

  static createFromBorder(canvas, { top, bottom, left, right }) {
    const topLeft = { x: left / canvas.width, y: top / canvas.height };
    const bottomRight = { x: right / canvas.width, y: bottom / canvas.height };
    return new this(canvas, topLeft, bottomRight);
  }

  get context() {
    return this._canvas.getContext('2d');
  }

  get width() {
    return (this._bottomRight.x - this._topLeft.x) * this._canvas.width;
  }

  get height() {
    return (this._bottomRight.y - this._topLeft.y) * this._canvas.height;
  }

  get top() {
    return this._topLeft.y * this._canvas.height;
  }

  set top(value) {
    if (value >= this.bottom) return;
    this._topLeft.y = value / this._canvas.height;
  }

  get bottom() {
    return this._bottomRight.y * this._canvas.height;
  }

  set bottom(value) {
    if (value <= this.top) return;
    this._bottomRight.y = value / this._canvas.height;
  }

  get left() {
    return this._topLeft.x * this._canvas.width;
  }

  set left(value) {
    if (value >= this.right) return;
    this._topLeft.x = value / this._canvas.width;
  }

  get right() {
    return this._bottomRight.x * this._canvas.width;
  }

  set right(value) {
    if (value <= this.left) return;
    this._bottomRight.x = value / this._canvas.width;
  }

  setColumns(columns) {
    this._columns = columns;
  }

  setColumnAtPosition(colIndex, position) {
    if (!this.includesPosition(position)) return;
    const min = this._columns[colIndex - 1] || 0;
    const max = this._columns[colIndex + 1] || 1;
    const xRatio = (position.x - this.left) / this.width;
    if (xRatio > min && xRatio < max) this._columns[colIndex] = xRatio;
  }

  draw() {
    const ctx = this.context;
    ctx.strokeRect(this.left, this.top, this.width, this.height);
    ctx.beginPath();
    this._columns.forEach((col) => {
      const xPos = this.left + this.width * col;
      ctx.moveTo(xPos, this.top);
      ctx.lineTo(xPos, this.bottom);
    });
    ctx.stroke();
  }

  highlight() {
    this.context.save();
    this.context.strokeStyle = 'green';
    this.context.lineWidth = 3;
    this.draw();
    this.context.restore();
  }

  includesPosition({ x, y }, accuracy = 1) {
    return (
      x >= this.left - accuracy && x <= this.right + accuracy && y >= this.top - accuracy && y <= this.bottom + accuracy
    );
  }

  getLineAt({ x, y }, accuracy = 1) {
    if (!this.includesPosition({ x, y }, accuracy)) return;
    if (Math.abs(this.top - y) <= accuracy && Math.abs(this.left - x) <= accuracy)
      return { type: 'corner', placement: 'topLeft' };
    if (Math.abs(this.top - y) <= accuracy && Math.abs(this.right - x) <= accuracy)
      return { type: 'corner', placement: 'topRight' };
    if (Math.abs(this.bottom - y) <= accuracy && Math.abs(this.left - x) <= accuracy)
      return { type: 'corner', placement: 'bottomLeft' };
    if (Math.abs(this.bottom - y) <= accuracy && Math.abs(this.right - x) <= accuracy)
      return { type: 'corner', placement: 'bottomRight' };
    if (Math.abs(this.top - y) <= accuracy) return { type: 'border', placement: 'top' };
    if (Math.abs(this.bottom - y) <= accuracy) return { type: 'border', placement: 'bottom' };
    if (Math.abs(this.left - x) <= accuracy) return { type: 'border', placement: 'left' };
    if (Math.abs(this.right - x) <= accuracy) return { type: 'border', placement: 'right' };

    const columnIndex = this._columns.findIndex((col) => {
      const xPos = this.left + this.width * col;
      return Math.abs(xPos - x) <= accuracy;
    });
    if (columnIndex !== -1) {
      return { type: 'column', placement: columnIndex };
    }
  }

  getRatioPoints() {
    return [this._topLeft, this._bottomRight];
  }

  getRatioColumns() {
    return this._columns.map((col) => {
      const xPos = this.left + this.width * col;
      return xPos / this._canvas.width;
    });
  }
}

const getPosition = (mouseEvent) => {
  const { left, top } = mouseEvent.target.getBoundingClientRect();
  const x = mouseEvent.clientX - left;
  const y = mouseEvent.clientY - top;
  return { x, y };
};

export default {
  props: {
    markEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      overLine: null,
    };
  },
  computed: {
    cursor() {
      if (this.overLine) {
        const { type, placement } = this.overLine.line;
        if (type === 'column') return 'col-resize';
        if (['top', 'bottom'].includes(placement)) return 'ns-resize';
        if (['left', 'right'].includes(placement)) return 'ew-resize';
        if (['topLeft', 'bottomRight'].includes(placement)) return 'nwse-resize';
        if (['topRight', 'bottomLeft'].includes(placement)) return 'nesw-resize';
      }
      return 'auto';
    },
  },
  created() {
    this.mousedown = null;
    this.startPoint = null;
    this.resizeLine = null;
    this.shapes = [];
    this.overShape = null;
  },
  mounted() {
    this.updateCanvasDimensions();
    this.observer = new MutationObserver(this.updateCanvasDimensions);
    this.observer.observe(this.$el.children[0], { attributes: true });
    window.addEventListener('resize', this.updateCanvasDimensions);
  },
  beforeDestroy() {
    this.observer.disconnect();
    window.removeEventListener('resize', this.updateCanvasDimensions);
  },
  methods: {
    updateCanvasDimensions() {
      const canvas = this.$refs.canvas;
      const { width, height } = canvas.getBoundingClientRect();
      Object.assign(canvas, { width, height });
    },
    onMouseDown(event) {
      const position = getPosition(event);
      this.mousedown = position;
      if (this.overLine) {
        this.startResize(this.overLine);
        return;
      }
      if (this.overShape) return;
      if (this.markEnabled) this.startMark();
    },
    onMouseMove(event) {
      this.redraw();
      const position = getPosition(event);
      if (this.mousedown) {
        if (this.resizeLine) this.updateResize(position);
        else if (this.markEnabled) this.updateMark(position);
      } else {
        this.overShape = this.shapes.find((shape) => shape.includesPosition(position));
        const shape = this.shapes.find((shape) => shape.getLineAt(position, 2));
        if (!shape) this.overLine = null;
        else this.overLine = { shape, line: shape.getLineAt(position, 2) };
      }
      const rect = event.target.getBoundingClientRect();
      this.$emit('cursormove', convertToRatio(position, rect));
    },
    onMouseUp(event) {
      if (!this.mousedown) return;
      const mousedown = this.mousedown;
      this.mousedown = null;
      const position = getPosition(event);
      if (this.resizeLine) {
        this.endResize();
        return;
      }
      if (this.overShape) {
        if (position.x === mousedown.x && position.y === mousedown.y) this.$emit('clickShape', this.overShape);
        return;
      }
      if (this.markEnabled) this.endMark(position);
    },
    startMark() {
      const rect = this.$refs.canvas.getBoundingClientRect();
      this.$emit('markStart', convertToRatio(this.mousedown, rect));
    },
    updateMark(position) {
      const box = Box.createFromPoints(this.$refs.canvas, [this.mousedown, position]);
      box.draw();
    },
    endMark(position) {
      this.redraw();
      const rect = this.$refs.canvas.getBoundingClientRect();
      this.$emit('markEnd', convertToRatio(position, rect));
    },
    startResize(line) {
      this.resizeLine = line;
    },
    updateResize(position) {
      const { line, shape } = this.resizeLine;
      const { type, placement } = line;
      if (type === 'border' || type === 'corner') {
        if (['top', 'topLeft', 'topRight'].includes(placement)) shape.top = position.y;
        if (['bottom', 'bottomLeft', 'bottomRight'].includes(placement)) shape.bottom = position.y;
        if (['left', 'topLeft', 'bottomLeft'].includes(placement)) shape.left = position.x;
        if (['right', 'topRight', 'bottomRight'].includes(placement)) shape.right = position.x;
      } else {
        // type === 'column'
        shape.setColumnAtPosition(placement, position);
      }
    },
    endResize() {
      this.resizeLine = null;
    },
    redraw() {
      this.clearCanvas();
      const ctx = this.$refs.canvas.getContext('2d');
      ctx.save();
      ctx.setLineDash([4, 2]);
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      this.shapes.forEach((shape) => shape.draw());
      ctx.restore();
      if (this.overShape) this.overShape.highlight();
    },
    clearCanvas() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      const { width, height } = canvas.getBoundingClientRect();
      ctx.clearRect(0, 0, width, height);
    },
    drawRect({ start, end }, options = {}) {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      const rect = canvas.getBoundingClientRect();

      const startPoint = convertFromRatio(start, rect);
      const endPoint = convertFromRatio(end, rect);
      const width = Math.abs(startPoint.x - endPoint.x);
      const height = Math.abs(startPoint.y - endPoint.y);
      ctx.strokeStyle = options.color || '#000';
      ctx.strokeRect(Math.min(startPoint.x, endPoint.x), Math.min(startPoint.y, endPoint.y), width, height);
    },
    createBox(startPoint, endPoint) {
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      const box = Box.createFromPoints(canvas, [convertFromRatio(startPoint, rect), convertFromRatio(endPoint, rect)]);
      box.draw();
      this.shapes.push(box);
      return box;
    },
    removeBox(box) {
      const index = this.shapes.indexOf(box);
      if (index !== -1) this.shapes.splice(index, 1);
      this.redraw();
    },
  },
};
</script>
