<template>
  <div ref="root" class="position-relative">
    <slot />
    <div class="position-absolute" style="top: 0; left: 0; bottom: 0; right: 0">
      <canvas ref="canvas" class="w-100 h-100" />
    </div>
  </div>
</template>

<script type="text/javascript">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import Rect from '../utils/rect';

const convertToRatio = ({ x, y }, { width, height }) => ({ x: x / width, y: y / height });
const convertFromRatio = ({ x, y }, { width, height }) => ({ x: x * width, y: y * height });

const useElMousePosition = (target) => {
  const x = ref(0);
  const y = ref(0);
  watch(
    target,
    (el, prevEl, onCleanup) => {
      if (!el) return;
      const moveHandler = (event) => {
        const { left, top } = el.getBoundingClientRect();
        x.value = event.clientX - left;
        y.value = event.clientY - top;
      };
      el.addEventListener('mousemove', moveHandler);
      onCleanup(() => el.removeEventListener('mousemove', moveHandler));
    },
    { immediate: true }
  );
  return { x, y };
};

const useElMousedown = (target) => {
  const mousedown = ref(false);
  const onMousedown = (event) => {
    if (event.which === 3) return;
    mousedown.value = true;
  };
  const onMouseup = (event) => {
    if (event.which === 3) return;
    mousedown.value = false;
  };
  watch(
    target,
    (el, prevEl, onCleanup) => {
      if (!el) return;
      el.addEventListener('mousedown', onMousedown);
      el.addEventListener('mouseup', onMouseup);
      onCleanup(() => {
        mousedown.value = false;
        el.removeEventListener('mousedown', onMousedown);
        el.removeEventListener('mouseup', onMouseup);
      });
    },
    { immediate: true }
  );
  return mousedown;
};

export default {
  props: {
    selectedRects: Array,
  },
  setup(props, { emit }) {
    const root = ref(null);
    const canvas = ref(null);

    const selectedRects = computed(() =>
      props.selectedRects.map((rect) => rect.map((coordinate) => convertFromRatio(coordinate, canvas.value)))
    );

    const updateCanvasDimensions = () => {
      const { width, height } = canvas.value.getBoundingClientRect();
      Object.assign(canvas.value, { width, height });
    };
    const observer = new MutationObserver(updateCanvasDimensions);
    onMounted(() => {
      updateCanvasDimensions();
      observer.observe(root.value.children[0], { attributes: true });
      window.addEventListener('resize', updateCanvasDimensions);
    });
    onBeforeUnmount(() => {
      observer.disconnect();
      window.removeEventListener('resize', updateCanvasDimensions);
    });

    const { x, y } = useElMousePosition(canvas);
    const mousedown = useElMousedown(canvas);

    // useSelectionFeature
    let mousedownPos = null;
    const selectionRect = ref(null);
    watch(
      mousedown,
      (mousedown) => {
        if (mousedown) {
          mousedownPos = { x: x.value, y: y.value };
          emit('selectionStart', convertToRatio(mousedownPos, canvas.value));
        } else {
          // mouseup
          if (!mousedownPos) return;
          // const selectionRect = Rect.createFromCoordinates(
          //   convertToRatio(mousedownPos, canvas.value),
          //   convertToRatio({ x: x.value, y: y.value}, canvas.value)
          // );
          emit(
            'selectionEnd',
            selectionRect.value.map((c) => convertToRatio(c, canvas.value))
          );
          mousedownPos = null;
          selectionRect.value = null;
        }
      },
      { immediate: true }
    );
    watch(
      [x, y, mousedown],
      ([x, y, mousedown]) => {
        if (!canvas.value) return;
        emit('cursormove', convertToRatio({ x, y }, canvas.value));
        if (mousedown) {
          selectionRect.value = Rect.createFromCoordinates(mousedownPos, { x, y });
        }
        redraw();
      },
      { immediate: true }
    );

    const redraw = () => {
      if (!canvas.value) return;
      const ctx = canvas.value.getContext('2d');
      const { width, height } = canvas.value;
      ctx.clearRect(0, 0, width, height);
      ctx.save();
      ctx.strokeStyle = 'rgb(6,103,222)';
      selectedRects.value.forEach((rect) => {
        ctx.strokeRect(rect.topLeft.x, rect.topLeft.y, rect.width, rect.height);
      });
      ctx.restore();

      const rect = selectionRect.value;
      if (!rect) return;
      ctx.save();
      ctx.strokeStyle = 'rgba(255,255,255,0.75)';
      ctx.fillStyle = 'rgba(255,255,255,0.1)';
      ctx.fillRect(rect.topLeft.x, rect.topLeft.y, rect.width, rect.height);
      ctx.strokeRect(rect.topLeft.x, rect.topLeft.y, rect.width, rect.height);
      ctx.restore();
    };

    watch(selectedRects, redraw, { immediate: true });

    onMounted(() => {
      canvas.value.addEventListener('contextmenu', (event) => {
        emit('contextmenu', event, convertToRatio({ x: x.value, y: y.value }, canvas.value));
      });
    });

    return { root, canvas };
  },
  created() {
    window.vm = this;
  },
  methods: {
    drawRect({ start, end }, options = {}) {
      const canvas = this.canvas;
      const ctx = canvas.getContext('2d');
      const rect = canvas.getBoundingClientRect();

      const startPoint = convertFromRatio(start, rect);
      const endPoint = convertFromRatio(end, rect);
      const width = Math.abs(startPoint.x - endPoint.x);
      const height = Math.abs(startPoint.y - endPoint.y);
      ctx.strokeStyle = options.color || '#000';
      ctx.strokeRect(Math.min(startPoint.x, endPoint.x), Math.min(startPoint.y, endPoint.y), width, height);
    },
  },
};
</script>
